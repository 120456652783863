import { api } from '../axios'
import { apiUrls } from '../helpers/apiHelper'
import plausible, { events } from '../helpers/plausibleHelper'

export const RETRIEVING_OFFER_PRODUCT = 'offers/RETRIEVING_OFFER_PRODUCT'
export const RETRIEVED_OFFER_PRODUCT = 'offers/RETRIEVED_OFFER_PRODUCT'
export const RETRIEVING_OFFER_PRODUCT_FAILURE =
  'offers/RETRIEVING_OFFER_PRODUCT_FAILURE'

export const SUBMITTING_OFFER = 'offers/SUBMITTING_OFFER'
export const SUBMITTED_OFFER = 'offers/SUBMITTED_OFFER'
export const SUBMITTING_OFFER_FAILURE = 'offers/SUBMITTING_OFFER_FAILURE'

export const CHECKING_VALID_OFFER = 'offers/CHECKING_VALID_OFFER'
export const CHECKED_VALID_OFFER = 'offers/CHECKED_VALID_OFFER'
export const CHECKING_VALID_OFFER_FAILURE =
  'offers/CHECKING_VALID_OFFER_FAILURE'

export const RETRIEVED_SUPPLIER_PRODUCTS = 'offers/RETRIEVED_SUPPLIER_PRODUCTS'

export const retrieveSupplierProducts = (productId) => {
  return (dispatch) => {
    api
      .get(apiUrls.other.customerSellingProducts, {
        params: { product_id: productId }
      })
      .then((response) => {
        dispatch({
          type: RETRIEVED_SUPPLIER_PRODUCTS,
          payload: response.data
        })
      })
      .catch((e) => {
        console.error(e)
      })
  }
}

export const retrieveOfferProduct = (productId) => {
  return (dispatch) => {
    dispatch({
      type: RETRIEVING_OFFER_PRODUCT
    })

    return api
      .post(apiUrls.user.makeOffer, {
        product_id: productId,
        quantity: 1
      })
      .then((response) => {
        dispatch({
          type: RETRIEVED_OFFER_PRODUCT,
          payload: response.data
        })
      })
      .catch((e) => {
        dispatch({
          type: RETRIEVING_OFFER_PRODUCT_FAILURE
        })
        console.error(e)
      })
  }
}

export const submitOffer = (data) => {
  return (dispatch) => {
    dispatch({
      type: SUBMITTING_OFFER
    })

    plausible.trackEvent(events.pharmacy.makeOffer, {
      productId: data.productId,
      price: data.price,
      supplierId: data.supplierId,
      quantity: data.quantity,
      originalPrice: data.originalPrice
    })

    const params = {
      supplier_id: data.supplierId,
      product_id: data.productId,
      price: data.price,
      quantity: data.quantity,
      supplier_products: data.supplierProducts,
      is_group_offer: data.makeMassOffer,
      shipping_method: data.shippingMethod
    }

    return api
      .post(apiUrls.offer.submitOffer, params)
      .then((response) => {
        dispatch({
          type: SUBMITTED_OFFER,
          payload: response.data
        })
      })
      .catch((e) => {
        dispatch({
          type: SUBMITTING_OFFER_FAILURE
        })
        console.error(e)
        alert(e.response.data.message)
      })
  }
}

export const checkValidOffer = (data) => {
  return (dispatch) => {
    dispatch({
      type: CHECKING_VALID_OFFER
    })

    return api
      .post(apiUrls.offer.verifyOffer, {
        supplier_id: data.supplierId,
        product_id: data.productId,
        price: data.price,
        quantity: data.quantity
      })
      .then((response) => {
        dispatch({
          type: CHECKED_VALID_OFFER,
          payload: response.data
        })
      })
      .catch((e) => {
        dispatch({
          type: CHECKING_VALID_OFFER_FAILURE
        })
        console.error(e)
      })
  }
}
