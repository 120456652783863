import _isEmpty from 'lodash.isempty'

const ALLOWED_ALL_ACTION = '*'

export const getKey = ([subjectClass, action] = []) =>
  subjectClass ? `${subjectClass}.${action || ALLOWED_ALL_ACTION}` : ''

export const canAllowPermisson = (requiredPermission, userPermission) => {
  const [subjectClass1, action1] = requiredPermission.split('.')
  const [subjectClass2, action2] = userPermission.split('.')
  if (subjectClass1 !== subjectClass2) return false
  return action1 === ALLOWED_ALL_ACTION || action1 === action2
}

export const WHOLESALER_ROUTE_PERMISSIONS = {
  '/suppliers/orders': getKey(['My Orders', 'orders']),
  '/suppliers/orders/:status': getKey(['My Orders', 'orders']),
  '/suppliers/orders/:id/view': getKey(['My Orders', 'orders']),
  '/suppliers/inventory': getKey(['Inventory', 'inventory']),
  '/suppliers/inventory/:status': getKey(['Inventory', 'inventory']),
  '/suppliers/invoices': getKey(['My Orders', 'invoices']),
  '/suppliers/invoices/:status': getKey(['My Orders', 'invoices']),
  '/suppliers/invoices/:id/view': getKey(['My Orders', 'invoices']),
  '/suppliers/offers': getKey(['My Orders', 'offers']),
  '/suppliers/offers/:status': getKey(['My Orders', 'offers']),
  '/suppliers/offers/:id/view': getKey(['My Orders', 'offers']),
  '/messages': getKey(['Messages', 'messages']),
  '/messages/:id': getKey(['Messages', 'messages']),
  '/settings': getKey(['Settings', 'account']),
  '/settings/security': getKey(['Settings', 'account'])
}

export const PHARMACY_ROUTE_PERMISSIONS = {
  '/orders': [
    getKey(['Manage Orders', 'view_orders']),
    getKey(['RPh2RPh Manage Orders', 'view_orders'])
  ],
  '/orders/received_p2p': getKey(['RPh2RPh Manage Orders', 'view_orders']),
  '/orders/received_p2p/:id': getKey(['RPh2RPh Manage Orders', 'view_orders']),
  '/orders/:id': [
    getKey(['Manage Orders', 'view_orders']),
    getKey(['RPh2RPh Manage Orders', 'view_orders'])
  ],
  '/orders/:id/edit': [
    getKey(['Manage Orders', 'edit_and_cancel']),
    getKey(['RPh2RPh Manage Orders', 'edit_and_cancel'])
  ],
  '/orders/:id/thankyou': [
    getKey(['Manage Orders', 'edit_and_cancel']),
    getKey(['RPh2RPh Manage Orders', 'edit_and_cancel'])
  ],
  '/billing': [
    getKey(['Manage Orders', 'invoices']),
    getKey(['RPh2RPh Manage Orders', 'invoices'])
  ],
  '/billing/:id/invoice': [
    getKey(['Manage Orders', 'invoices']),
    getKey(['RPh2RPh Manage Orders', 'invoices'])
  ],
  '/cart': getKey(['Manage Orders', 'cart']),
  '/cart/:cartId/details': getKey(['Manage Orders', 'cart']),
  '/cart/:cartId/tos': getKey(['Manage Orders', 'cart']),
  '/cart/:cartId/laws': getKey(['Manage Orders', 'cart']),
  '/cart/:cartId/fees': getKey(['Manage Orders', 'cart']),
  '/cart/:cartId/faqs': getKey(['Manage Orders', 'cart']),
  '/pedigrees': getKey(['Miscellaneous', 'pedigrees']),
  '/wholesalers/apply': getKey(['Miscellaneous', 'wholesalers']),
  '/wholesalers/pending': getKey(['Miscellaneous', 'wholesalers']),
  '/wholesalers/approved': getKey(['Miscellaneous', 'wholesalers']),
  '/wholesalers/:id': getKey(['Miscellaneous', 'wholesalers']),
  '/rewards': getKey(['Miscellaneous', 'rewards']),
  '/invite': getKey(['Miscellaneous', 'rewards']),
  '/messages': getKey(['Miscellaneous', 'messages']),
  '/messages/:id': getKey(['Miscellaneous', 'messages']),
  '/pharmacy/inventory': getKey(['RPh2RPh Inventory', 'inventory']),
  '/deals': getKey(['Promotions', 'deals']),
  '/pharmacy/basket_with_deals': getKey(['Promotions', 'deals']),
  '/shortdates': getKey(['Promotions', 'short_dates']),
  '/offers': [
    getKey(['Offers', 'view_offers']),
    getKey(['RPh2RPh Offers', 'view_offers'])
  ],
  '/offers/history': [
    getKey(['Offers', 'view_offers']),
    getKey(['RPh2RPh Offers', 'view_offers'])
  ],
  '/offers/:id': [
    getKey(['Offers', 'view_offers']),
    getKey(['RPh2RPh Offers', 'view_offers'])
  ],
  '/settings': getKey(['Settings', 'account']),
  '/settings/prefs': getKey(['Settings', 'preferences']),
  '/settings/switch_location': getKey(['Settings', 'locations']),
  '/settings/security': getKey(['Settings', 'security']),
  '/settings/licenses': getKey(['Settings', 'account']),
  '/settings/payments': getKey(['Settings', 'payments'])
}

export const checkUserPermission = (user, pathPermission) => {
  if (_isEmpty(pathPermission)) return true

  const { permissions: userPermissions = {} } = user
  if (_isEmpty(userPermissions)) return false

  const { allowAll, permissions = [] } = userPermissions
  if (allowAll) return true

  const found = permissions.find((item) =>
    canAllowPermisson(pathPermission, item)
  )

  return found
}

export const checkMultiplePermissions = (user, pathPermissions) => {
  if (Array.isArray(pathPermissions)) {
    return pathPermissions.some((permission) =>
      checkUserPermission(user, permission)
    )
  }
  return checkUserPermission(user, pathPermissions)
}

export const getEnabledLinkByPermission = (user, links = []) => {
  return (
    links.find((link) =>
      checkMultiplePermissions(user, PHARMACY_ROUTE_PERMISSIONS[link])
    ) || ''
  )
}
