import React from 'react'
import ClipLoader from 'react-spinners/ClipLoader'

const LoadingState = React.memo((props) => {
  return (
    <div className={`loading-state ${props.classes ? props.classes : ''}`}>
      <ClipLoader
        css="display:block;margin: 0 auto;"
        sizeUnit="px"
        size={props.size || 40}
        color="#36aeea"
        loading
      />
    </div>
  )
})

export const withLoadingState = (Component, loadingProps) => (props) => {
  return props.loading ? (
    <LoadingState {...loadingProps} />
  ) : (
    <Component {...props} />
  )
}

export default LoadingState
