import {
  RETRIEVING_ORDERS,
  RETRIEVED_ORDERS,
  RETRIEVING_ORDERS_FAILURE,
  RETRIEVING_ORDER_METADATA,
  RETRIEVED_ORDER_METADATA,
  RETRIEVING_ORDER_METADATA_FAILURE,
  RETRIEVING_ORDER_LINE_ITEMS,
  RETRIEVED_ORDER_LINE_ITEMS,
  RETRIEVING_ORDER_LINE_ITEMS_FAILURE,
  FULFILLING_ORDER,
  FULFILLED_ORDER,
  FULFILLING_ORDER_FAILURE
} from '../actions/orders'

const initialState = {
  loading: false,
  orders: [],
  hasMoreOrders: false,
  totalPages: 0,
  metadata: [],
  lineItemsLoading: false,
  lineItems: []
}

const renderOrders = (alreadyFetchedOrders, currentPage) => {
  const resetOrders = currentPage === 1
  const orders = resetOrders ? [] : alreadyFetchedOrders
  return orders
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RETRIEVING_ORDERS:
      // we want to show loader on initial page load
      // that's why action.currentPage === 1
      return {
        ...state,
        loading: action.currentPage === 1
      }
    case RETRIEVED_ORDERS:
      return {
        ...state,
        loading: false,
        orders: [
          ...renderOrders(state.orders, action.currentPage),
          ...action.payload.orders
        ],
        totalPages: action.payload.total_pages,
        hasMoreOrders: action.payload.total_pages > action.currentPage
      }
    case RETRIEVING_ORDERS_FAILURE:
      return {
        ...state,
        loading: false,
        orders: [],
        hasMoreOrders: false,
        totalPages: 0
      }
    case RETRIEVING_ORDER_METADATA:
      return {
        ...state,
        loading: true,
        metadata: [],
        lineItems: []
      }
    case RETRIEVED_ORDER_METADATA:
      return {
        ...state,
        loading: false,
        metadata: action.payload
      }
    case RETRIEVING_ORDER_METADATA_FAILURE:
      return {
        ...state,
        loading: false,
        metadata: [],
        lineItems: []
      }
    case RETRIEVING_ORDER_LINE_ITEMS:
      return {
        ...state,
        lineItemsLoading: true,
        lineItems: []
      }
    case RETRIEVED_ORDER_LINE_ITEMS:
      return {
        ...state,
        lineItemsLoading: false,
        lineItems: action.payload
      }
    case RETRIEVING_ORDER_LINE_ITEMS_FAILURE:
      return {
        ...state,
        lineItemsLoading: false,
        lineItems: []
      }
    case FULFILLING_ORDER:
      return {
        ...state,
        loading: true
      }
    case FULFILLED_ORDER:
      return {
        ...state,
        loading: false,
        metadata: action.payload
      }
    case FULFILLING_ORDER_FAILURE:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}
