import {
  FETCHING_APPLY_WHOLESALERS,
  FETCHED_APPLY_WHOLESALERS,
  FETCHING_APPLY_WHOLESALERS_FAILURE,
  LINKING_EXISTING_ACCOUNT,
  LINKED_EXISTING_ACCOUNT,
  LINKING_EXISTING_ACCOUNT_FAILURE,
  FETCHING_PENDING_WHOLESALERS,
  FETCHED_PENDING_WHOLESALERS,
  FETCHING_PENDING_WHOLESALERS_FAILURE,
  FETCHING_MY_WHOLESALERS,
  FETCHED_MY_WHOLESALERS,
  FETCHING_MY_WHOLESALERS_FAILURE,
  REQUESTING_CREDIT_LIMIT_INCREASE,
  REQUESTED_CREDIT_LIMIT_INCREASE,
  REQUESTING_CREDIT_LIMIT_INCREASE_FAILURE,
  LOADING_CUSTOMER_APPLICATION,
  LOADED_CUSTOMER_APPLICATION,
  LOADING_CUSTOMER_APPLICATION_FAILURE,
  FETCHING_WHOLESALER_METADATA,
  FETCHED_WHOLESALER_METADATA,
  FETCHING_WHOLESALER_METADATA_FAILURE,
  FETCHING_WHOLESALER_PRODUCTS,
  FETCHED_WHOLESALER_PRODUCTS,
  FETCHING_WHOLESALER_PRODUCTS_FAILURE
} from '../actions/wholesalers'

const initialState = {
  loading: false,
  productsLoading: false,
  submittingForm: false,
  successMessage: '',
  errorMessage: '',
  applyWholesalers: [],
  pendingWholesalers: [],
  myWholesalers: [],
  loadingCustomerApplication: false,
  signatureRequest: [],
  requestCreditLimitIncreaseIds: [],
  applyControlsIds: [],
  wholesaler: [],
  products: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_APPLY_WHOLESALERS:
      return {
        ...state,
        loading: true,
        applyWholesalers: []
      }
    case FETCHED_APPLY_WHOLESALERS:
      return {
        ...state,
        loading: false,
        applyWholesalers: action.payload
      }
    case FETCHING_APPLY_WHOLESALERS_FAILURE:
      return {
        ...state,
        loading: false,
        applyWholesalers: []
      }
    case LINKING_EXISTING_ACCOUNT:
      return {
        ...state,
        submittingForm: true,
        error: ''
      }
    case LINKED_EXISTING_ACCOUNT:
      state.wholesalers = state.wholesalers.filter(w => {
        return w.id != action.payload.customer.supplier_id
      })
      return {
        ...state,
        submittingForm: false,
        wholesalers: state.wholesalers
      }
    case LINKING_EXISTING_ACCOUNT_FAILURE:
      return {
        ...state,
        submittingForm: false,
        error: action.message
      }
    case FETCHING_PENDING_WHOLESALERS:
      return {
        ...state,
        loading: true,
        pendingWholesalers: []
      }
    case FETCHED_PENDING_WHOLESALERS:
      return {
        ...state,
        loading: false,
        pendingWholesalers: action.payload
      }
    case FETCHING_PENDING_WHOLESALERS_FAILURE:
      return {
        ...state,
        loading: false,
        pendingWholesalers: []
      }
    case FETCHING_MY_WHOLESALERS:
      return {
        ...state,
        loading: true,
        myWholesalers: []
      }
    case FETCHED_MY_WHOLESALERS:
      return {
        ...state,
        loading: false,
        myWholesalers: action.payload
      }
    case FETCHING_MY_WHOLESALERS_FAILURE:
      return {
        ...state,
        loading: false,
        myWholesalers: []
      }
    case LOADING_CUSTOMER_APPLICATION:
      return {
        ...state,
        loadingCustomerApplication: true
      }
    case LOADED_CUSTOMER_APPLICATION:
      return {
        ...state,
        loadingCustomerApplication: false,
        signatureRequest: action.payload
      }
    case LOADING_CUSTOMER_APPLICATION_FAILURE:
      return {
        ...state,
        loadingCustomerApplication: false,
        signatureRequest: []
      }
    case REQUESTING_CREDIT_LIMIT_INCREASE:
      return {
        ...state,
        submittingForm: true,
        errorMessage: ''
      }
    case REQUESTED_CREDIT_LIMIT_INCREASE:
      state.requestCreditLimitIncreaseIds.push(
        action.payload.customer.supplier_id
      )
      return {
        ...state,
        submittingForm: false,
        successMessage: action.payload.message,
        requestCreditLimitIncreaseIds: state.requestCreditLimitIncreaseIds
      }
    case REQUESTING_CREDIT_LIMIT_INCREASE_FAILURE:
      return {
        ...state,
        submittingForm: false,
        errorMessage: action.message
      }
    case FETCHING_WHOLESALER_METADATA:
      return {
        ...state,
        loading: true,
        wholesaler: []
      }
    case FETCHED_WHOLESALER_METADATA:
      return {
        ...state,
        loading: false,
        wholesaler: action.payload
      }
    case FETCHING_WHOLESALER_METADATA_FAILURE:
      return {
        ...state,
        loading: false
      }
    case FETCHING_WHOLESALER_PRODUCTS:
      return {
        ...state,
        productsLoading: true,
        products: []
      }
    case FETCHED_WHOLESALER_PRODUCTS:
      return {
        ...state,
        productsLoading: false,
        products: action.payloads
      }
    case FETCHING_WHOLESALER_PRODUCTS_FAILURE:
      return {
        ...state,
        productsLoading: false,
        products: []
      }
    default:
      return state
  }
}
