import React, { useEffect } from 'react'
import ReactPixel from 'react-facebook-pixel'

export const FbPixelTracker = (WrappedComponent) => {
  return (props) => {
    useEffect(() => {
      const options = {
        autoConfig: true
      }

      ReactPixel.init(process.env.RAZZLE_FACEBOOK_PIXEL_ID, options)
      ReactPixel.pageView()
    }, [])

    return <WrappedComponent {...props} />
  }
}
