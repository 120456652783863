import _isEmpty from 'lodash.isempty'
import { WHOLESALER_ROUTE_PERMISSIONS } from './permissionHelper'

export const baseUrl = () => {
  if (window === undefined) return ''
  return `${window.location.protocol}//${window.location.host}`
}

export const encodeLocationPath = (location) => {
  const { pathname, search } = location
  return encodeURIComponent(`${pathname}${search}`)
}

export const getQueryParam = (param, location) => {
  const { search } = location
  const params = new URLSearchParams(search)
  return params.get(param) || ''
}

export const fetchExistingURLParams = (params) =>
  Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&')

const SUPPLIER_HOME_PATH = '/suppliers/orders'

export const getWholesalerHomePath = (user) => {
  const { permissions, allowAll } = user.permissions || {}
  if (allowAll || _isEmpty(permissions)) return SUPPLIER_HOME_PATH
  const [firstFoundPermission] = permissions
  const [path] =
    Object.entries(WHOLESALER_ROUTE_PERMISSIONS).find(
      ([, permissionKey]) => permissionKey === firstFoundPermission
    ) || []
  return path || SUPPLIER_HOME_PATH
}

export const checkPathnameHOF = (paths) => (_, location) =>
  paths.some((path) => location.pathname.includes(path))

export const checkSearchParamsHOF = (params) => (_, location) =>
  location.search.includes(params)
