import user from './user'
import search from './search'
import cart from './cart'
import wholesalers from './wholesalers'
import orders from './orders'
import offers from './offers'
import deals from './deals'
import marketplace from './marketplace'
import messages from './messages'

const reducers = {
  user,
  search,
  cart,
  wholesalers,
  orders,
  offers,
  deals,
  marketplace,
  messages
}
export default reducers
