import {
  RETRIEVING_OFFER_PRODUCT,
  RETRIEVED_OFFER_PRODUCT,
  RETRIEVING_OFFER_PRODUCT_FAILURE,
  SUBMITTING_OFFER,
  SUBMITTED_OFFER,
  SUBMITTING_OFFER_FAILURE,
  CHECKING_VALID_OFFER,
  CHECKED_VALID_OFFER,
  CHECKING_VALID_OFFER_FAILURE,
  RETRIEVED_SUPPLIER_PRODUCTS
} from '../actions/offers'

const initialState = {
  loading: false,
  parentProduct: [],
  checkingValidity: false,
  validityStatus: [],
  submitting: false,
  submitted: false,
  submittedVariantIds: [],
  shippingMethods: [],
  responseMessageType: 'normal',
  supplierProducts: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RETRIEVING_OFFER_PRODUCT:
      return {
        ...state,
        loading: true,
        submitted: false,
        parentProduct: [],
        validityStatus: []
      }
    case RETRIEVED_OFFER_PRODUCT:
      return {
        ...state,
        loading: false,
        parentProduct: action.payload.product_properties,
        shippingMethods: action.payload.shipping_methods
      }
    case RETRIEVING_OFFER_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false
      }
    case SUBMITTING_OFFER:
      return {
        ...state,
        submitting: true
      }
    case SUBMITTED_OFFER:
      state.submittedVariantIds.push(action.payload.variant_id)
      return {
        ...state,
        submitting: false,
        submitted: true,
        submittedVariantIds: state.submittedVariantIds
      }
    case SUBMITTING_OFFER_FAILURE:
      return {
        ...state,
        submitting: false
      }
    case CHECKING_VALID_OFFER:
      return {
        ...state,
        checkingValidity: true
      }
    case CHECKED_VALID_OFFER:
      return {
        ...state,
        checkingValidity: false,
        validityStatus: action.payload
      }
    case CHECKING_VALID_OFFER_FAILURE:
      return {
        ...state,
        checkingValidity: false
      }
    case RETRIEVED_SUPPLIER_PRODUCTS:
      return {
        ...state,
        supplierProducts: action.payload || []
      }
    default:
      return state
  }
}
