import { api } from '../axios'
import { apiUrls } from '../helpers/apiHelper'

export const FETCHING_DEALS = 'deals/FETCHING_DEALS'
export const FETCHED_DEALS = 'deals/FETCHED_DEALS'
export const FETCHING_DEALS_FAILURE = 'deals/FETCHING_DEALS_FAILURE'

export const getDeals = (limit) => {
  return (dispatch) => {
    dispatch({
      type: FETCHING_DEALS
    })

    return api
      .get(apiUrls.deals.getDeals, {
        params: {
          per_page: limit
        }
      })
      .then((response) => {
        dispatch({
          type: FETCHED_DEALS,
          payload: response.data
        })
      })
      .catch((e) => {
        dispatch({
          type: FETCHING_DEALS_FAILURE
        })
        console.error(e)
      })
  }
}
