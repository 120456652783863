import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistCombineReducers } from 'redux-persist'
import { CookieStorage } from 'redux-persist-cookie-storage'
import Cookies from 'cookies-js'
import thunk from 'redux-thunk'
import reducers from '../reducers'
import { composeWithDevTools } from 'redux-devtools-extension'

const enhancers = []
const middleware = [thunk]
const persistConfig = {
  key: 'root',
  storage: new CookieStorage(Cookies, {
  	expiration: {
      'default': 730 * 86400 // Cookies expire after 2 years(almost permanent)
    }
  }),
  whitelist: 'user'
}

const composedEnhancers = compose(
  composeWithDevTools(applyMiddleware(...middleware)),
  ...enhancers
)
const rootReducer = persistCombineReducers(persistConfig, reducers)
export const reduxStore = createStore(rootReducer, composedEnhancers)

export const reduxPersistor = persistStore(reduxStore, {})
