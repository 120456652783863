export * from './FbPixelTracker'
export * from './GATracker'
export * from './LinkedinTracker'

export const withTrackers = (WrappedComponent) => (trackers) => {
  return trackers.reduce(
    (Component, tracker) => tracker(Component),
    WrappedComponent
  )
}
