import axios from 'axios'
import { encodeLocationPath } from './helpers/urlHelper'

export const clientCredentials = () =>
  `${process.env.RAZZLE_CLIENT_KEY}:${process.env.RAZZLE_CLIENT_SECRET}`

export const api = axios.create({
  baseURL: process.env.RAZZLE_API_BASE_URL,
  withCredentials: true,
  headers: {
    'Authorization-Client': 'Ezrirx-v3'
  }
})

// redirect user to /401 in case of unauthorized response
api.interceptors.response.use(
  (response) => response,
  (err) => {
    if (
      err.response.status === 401 &&
      err.response.data.message === 'Invalid access token'
    ) {
      window.location = `/401?from=${encodeLocationPath(window.location)}`
    }
    return Promise.reject(err)
  }
)

export const publicApi = axios.create({
  baseURL: process.env.RAZZLE_API_BASE_URL,
  withCredentials: true,
  headers: {
    Authorization: clientCredentials()
  }
})
