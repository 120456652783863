import { api } from '../axios'
import { apiUrls } from '../helpers/apiHelper'

export const FETCHING_PRODUCT = 'marketplace/FETCHING_PRODUCT'
export const FETCHED_PRODUCT = 'marketplace/FETCHED_PRODUCT'
export const FETCHING_PRODUCT_FAILURE = 'marketplace/FETCHING_PRODUCT_FAILURE'
export const SWITCHING_TAB = 'marketplace/SWITCHING_TAB'

export const getProduct = (productId, activeTab, switchingTab) => {
  return dispatch => {
    dispatch({
      type: switchingTab ? SWITCHING_TAB : FETCHING_PRODUCT
    })

    return api
      .get(apiUrls.product.getProduct(productId), {
        params: {
          vawd_status: activeTab
        }
      })
      .then(response => {
        dispatch({
          type: FETCHED_PRODUCT,
          payload: response.data
        })
      })
      .catch(e => {
        dispatch({
          type: FETCHING_PRODUCT_FAILURE
        })
        console.error(e)
      })
  }
}
