import {
  FETCHING_PRODUCT,
  FETCHED_PRODUCT,
  FETCHING_PRODUCT_FAILURE,
  SWITCHING_TAB
} from '../actions/marketplace'

const initialState = {
  productLoading: true,
  wholesalersLoading: false,
  viewingProduct: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_PRODUCT:
      return {
        ...state,
        productLoading: true,
        viewingProduct: []
      }
    case FETCHED_PRODUCT:
      return {
        ...state,
        productLoading: false,
        wholesalersLoading: false,
        viewingProduct: action.payload
      }
    case FETCHING_PRODUCT_FAILURE:
      return {
        ...state,
        productLoading: false,
        wholesalersLoading: false,
        viewingProduct: []
      }
    case SWITCHING_TAB:
      return {
        ...state,
        wholesalersLoading: true
      }
    default:
      return state
  }
}
