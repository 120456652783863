import Plausible from 'plausible-tracker'

export const events = {
  pharmacy: {
    addToCart: { action: 'pharmacy.addToCart' },
    applyForWholesaler: { action: 'pharmacy.applyForWholesaler' },
    placeOrder: { action: 'pharmacy.placeOrder' },
    acceptOffer: { action: 'pharmacy.acceptOffer' },
    cancelOffer: { action: 'pharmacy.cancelOffer' },
    requestCreditLimit: { action: 'pharmacy.requestCreditLimit' },
    payInvoice: { action: 'pharmacy.payInvoice' },
    makeOffer: { action: 'pharmacy.makeOffer' },
    searchProduct: { action: 'pharmacy.searchProduct' }
  }
}

class PlausibleHelper {
  instance = null

  init() {
    if (!this.instance && process.env.NODE_ENV === 'production') {
      this.instance = Plausible({
        domain: 'm.ezrirx.com'
        // trackLocalhost: true
      })
    }
  }

  trackPageview(props) {
    if (!this.instance) return
    this.instance.trackPageview(props)
  }

  trackEvent(event, props) {
    if (!this.instance) return
    this.instance.trackEvent(event.action, { props })
  }
}

const plausible = new PlausibleHelper()

export default plausible
