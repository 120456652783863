import { api } from '../axios'
import { apiUrls } from '../helpers/apiHelper'
import plausible, { events } from '../helpers/plausibleHelper'

export const FETCHING_APPLY_WHOLESALERS =
  'wholesalers/FETCHING_APPLY_WHOLESALERS'
export const FETCHED_APPLY_WHOLESALERS = 'wholesalers/FETCHED_APPLY_WHOLESALERS'
export const FETCHING_APPLY_WHOLESALERS_FAILURE =
  'wholesalers/FETCHING_APPLY_WHOLESALERS_FAILURE'
export const LINKING_EXISTING_ACCOUNT = 'wholesalers/LINKING_EXISTING_ACCOUNT'
export const LINKED_EXISTING_ACCOUNT = 'wholesalers/LINKED_EXISTING_ACCOUNT'
export const LINKING_EXISTING_ACCOUNT_FAILURE =
  'wholesalers/LINKING_EXISTING_ACCOUNT_FAILURE'

export const FETCHING_PENDING_WHOLESALERS =
  'wholesalers/FETCHING_PENDING_WHOLESALERS'
export const FETCHED_PENDING_WHOLESALERS =
  'wholesalers/FETCHED_PENDING_WHOLESALERS'
export const FETCHING_PENDING_WHOLESALERS_FAILURE =
  'wholesalers/FETCHING_PENDING_WHOLESALERS_FAILURE'

export const FETCHING_MY_WHOLESALERS = 'wholesalers/FETCHING_MY_WHOLESALERS'
export const FETCHED_MY_WHOLESALERS = 'wholesalers/FETCHED_MY_WHOLESALERS'
export const FETCHING_MY_WHOLESALERS_FAILURE =
  'wholesalers/FETCHING_MY_WHOLESALERS_FAILURE'
export const REQUESTING_CREDIT_LIMIT_INCREASE =
  'wholesalers/REQUESTING_CREDIT_LIMIT_INCREASE'
export const REQUESTED_CREDIT_LIMIT_INCREASE =
  'wholesalers/REQUESTED_CREDIT_LIMIT_INCREASE'
export const REQUESTING_CREDIT_LIMIT_INCREASE_FAILURE =
  'wholesalers/REQUESTING_CREDIT_LIMIT_INCREASE_FAILURE'

export const LOADING_CUSTOMER_APPLICATION =
  'wholesalers/LOADING_CUSTOMER_APPLICATION'
export const LOADED_CUSTOMER_APPLICATION =
  'wholesalers/LOADED_CUSTOMER_APPLICATION'
export const LOADING_CUSTOMER_APPLICATION_FAILURE =
  'wholesalers/LOADING_CUSTOMER_APPLICATION_FAILURE'

export const FETCHING_WHOLESALER_METADATA =
  'wholesalers/FETCHING_WHOLESALER_METADATA'
export const FETCHED_WHOLESALER_METADATA =
  'wholesalers/FETCHED_WHOLESALER_METADATA'
export const FETCHING_WHOLESALER_METADATA_FAILURE =
  'wholesalers/FETCHING_WHOLESALER_METADATA_FAILURE'

export const FETCHING_WHOLESALER_PRODUCTS =
  'wholesalers/FETCHING_WHOLESALER_PRODUCTS'
export const FETCHED_WHOLESALER_PRODUCTS =
  'wholesalers/FETCHED_WHOLESALER_PRODUCTS'
export const FETCHING_WHOLESALER_PRODUCTS_FAILURE =
  'wholesalers/FETCHING_WHOLESALER_PRODUCTS_FAILURE'

export const loadApplyWholesalers = (filters = 'all') => {
  return (dispatch) => {
    dispatch({
      type: FETCHING_APPLY_WHOLESALERS
    })

    return api
      .get(apiUrls.wholesalers.getApplyWholesalers, {
        params: {
          filters: filters
        }
      })
      .then((response) => {
        dispatch({
          type: FETCHED_APPLY_WHOLESALERS,
          payload: response.data
        })
      })
      .catch((e) => {
        dispatch({
          type: FETCHING_APPLY_WHOLESALERS_FAILURE
        })
        console.error(e)
      })
  }
}

export const linkExistingAccount = (supplierId) => {
  return (dispatch) => {
    dispatch({
      type: LINKING_EXISTING_ACCOUNT
    })

    return api
      .post(apiUrls.wholesalers.linkWholesalerAccount(supplierId), {})
      .then((response) => {
        dispatch({
          type: LINKED_EXISTING_ACCOUNT,
          payload: response.data
        })
      })
      .catch((e) => {
        dispatch({
          type: LINKING_EXISTING_ACCOUNT_FAILURE,
          message: e.message
        })
        console.error(e)
      })
  }
}

export const loadPendingWholesalers = (filters = 'all') => {
  return (dispatch) => {
    dispatch({
      type: FETCHING_PENDING_WHOLESALERS
    })

    return api
      .get(apiUrls.wholesalers.getWholesalers, {
        params: {
          filter: 'pending',
          filters: filters
        }
      })
      .then((response) => {
        dispatch({
          type: FETCHED_PENDING_WHOLESALERS,
          payload: response.data
        })
      })
      .catch((e) => {
        dispatch({
          type: FETCHING_PENDING_WHOLESALERS_FAILURE
        })
        console.error(e)
      })
  }
}

export const loadMyWholesalers = (filters = 'all') => {
  return (dispatch) => {
    dispatch({
      type: FETCHING_MY_WHOLESALERS
    })

    return api
      .get(apiUrls.wholesalers.getWholesalers, {
        params: {
          filters
        }
      })
      .then((response) => {
        dispatch({
          type: FETCHED_MY_WHOLESALERS,
          payload: response.data
        })
      })
      .catch((e) => {
        dispatch({
          type: FETCHING_MY_WHOLESALERS_FAILURE
        })
        console.error(e)
      })
  }
}

export const requestCreditLimitIncrease = (supplierId, data) => {
  return (dispatch) => {
    dispatch({
      type: REQUESTING_CREDIT_LIMIT_INCREASE
    })

    plausible.trackEvent(events.pharmacy.requestCreditLimit, { supplierId })

    return api
      .post(
        apiUrls.wholesalers.requestWholesalerCreditLimitIncrease(supplierId),
        {
          credit_requested: data.creditRequested,
          message: data.message
        }
      )
      .then((response) => {
        dispatch({
          type: REQUESTED_CREDIT_LIMIT_INCREASE,
          payload: response.data
        })
      })
      .catch((e) => {
        dispatch({
          type: REQUESTING_CREDIT_LIMIT_INCREASE_FAILURE,
          message: e.response.data.message
        })
        alert(e.response.data.message)
      })
  }
}

export const getWholesalerMetadata = (supplierId) => {
  return (dispatch) => {
    dispatch({
      type: FETCHING_WHOLESALER_METADATA
    })

    return api
      .get(apiUrls.wholesalers.getWholesalerDetails(supplierId))
      .then((response) => {
        dispatch({
          type: FETCHED_WHOLESALER_METADATA,
          payload: response.data
        })
      })
      .catch((e) => {
        dispatch({
          type: FETCHING_WHOLESALER_METADATA_FAILURE
        })
        console.error(e)
      })
  }
}

export const getWholesalerProducts = (supplierId) => {
  return (dispatch) => {
    dispatch({
      type: FETCHING_WHOLESALER_PRODUCTS
    })

    return api
      .get(apiUrls.wholesalers.getWholesalerProducts(supplierId))
      .then((response) => {
        dispatch({
          type: FETCHED_WHOLESALER_PRODUCTS,
          payload: response.data
        })
      })
      .catch((e) => {
        dispatch({
          type: FETCHING_WHOLESALER_PRODUCTS_FAILURE
        })
        console.error(e)
      })
  }
}
