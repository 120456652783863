export const events = {
  login: 'Login',
  purchase: 'Purchase'
}

export const fbPixelTrack = (event, data) => {
  if (typeof fbq !== 'undefined') {
    window.fbq('track', event, data)
  }
}
