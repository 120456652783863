import {
  FETCHING_DEALS,
  FETCHED_DEALS,
  FETCHING_DEALS_FAILURE
} from '../actions/deals'

const initialState = {
  loading: true,
  deals: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_DEALS:
      return {
        ...state,
        loading: true,
        deals: {}
      }
    case FETCHED_DEALS:
      return {
        ...state,
        loading: false,
        deals: action.payload
      }
    case FETCHING_DEALS_FAILURE:
      return {
        ...state,
        loading: false,
        deals: {}
      }
    default:
      return state
  }
}
