import _get from 'lodash.get'
import _isEmpty from 'lodash.isempty'

export const ROLE = {
  pharmacy: 'pharmacy',
  wholesaler: 'wholesaler'
}

const USER_PROPS_BY_ROLE = {
  onboarding: {
    [ROLE.pharmacy]: 'user_onboarding',
    [ROLE.wholesaler]: 'supplier.user_onboarding'
  },
  displayName: {
    [ROLE.pharmacy]: 'display_name',
    [ROLE.wholesaler]: 'supplier.supplier_store.name'
  }
}

export const getUserRole = (user) => _get(user, 'role', ROLE.pharmacy)

const getUserNestedProp = (paths, defaultValue) => (user) => {
  const role = getUserRole(user)
  return _get(user, paths[role]) || defaultValue
}

export const getUserOnboarding = getUserNestedProp(
  USER_PROPS_BY_ROLE.onboarding,
  {}
)

export const getUserDisplayName = (user) =>
  getUserNestedProp(USER_PROPS_BY_ROLE.displayName, '')(user) || user.dea_number

export const checkUserRole = (user, roles = []) => {
  const role = getUserRole(user)
  return _isEmpty(roles) || roles.includes(role)
}

export const isWholesaler = (user) => getUserRole(user) === ROLE.wholesaler

export const checkIfWholesalerHasPermission = (user) => {
  if (!isWholesaler(user)) return true
  return Boolean(user.permissions)
}

export const getPharmacyPermissions = (userObj) => {
  const { pharmacy_type_permissions: permissions = [] } = userObj
  if (permissions.allow_all) {
    return {
      allowAll: true,
      permissions: []
    }
  }

  return {
    allowAll: false,
    permissions: permissions.map(
      (permission) => `${permission.subject_class}.${permission.action}`
    )
  }
}

export const getWholesalerPermissions = (userObj) => {
  const { supplier_type_permissions: permissions = [] } = userObj
  if (permissions.allow_all) {
    return {
      allowAll: true,
      permissions: []
    }
  }

  return {
    allowAll: false,
    permissions: permissions.map(
      (permission) => `${permission.subject_class}.${permission.action}`
    )
  }
}

export const isReceivedP2P = (supplier, userData) => {
  return supplier.p2p_supplier && +supplier.id === +userData.supplier_id
}
