export const apiUrls = {
  cart: {
    getCart: '/api/v1/cart',
    getLineItems: '/api/v1/cart/line_items',
    getCartDetails: (cartId) => `/api/v1/cart/${cartId}/details`,
    getCartLineItems: (cartId) => `/api/v1/cart/${cartId}/line_items`,
    getSugessions: (supplierId) => `/api/v1/cart/${supplierId}/suggestions`,
    swapLineItem: (supplierId) => `/api/v1/cart/${supplierId}/swap`,
    addToCart: (supplierId) => `/api/v1/cart/${supplierId}/add_to_cart`,
    updateLineItem: (supplierId, lineItemId) =>
      `/api/v1/cart/${supplierId}/line_item/${lineItemId}`,
    removeLineItem: (supplierId, lineItemId) =>
      `/api/v1/cart/${supplierId}/line_item/${lineItemId}`,
    saveLineItem: (supplierId, lineItemId) =>
      `/api/v1/cart/${supplierId}/line_item/${lineItemId}/save`,
    requiredLineItem: (cartId, lineItemId) =>
      `/api/v1/cart/${cartId}/line_item/${lineItemId}/required`,
    getLineItemStatus: (cartId, lineItemId) =>
      `/api/v1/cart/${cartId}/line_item/${lineItemId}/status`,
    getCartProducts: '/api/v1/cart/products',
    getCartAddMore: (supplierId) => `/api/v1/cart/${supplierId}/add_more`,
    getCartSavedForLater: (supplierId) => `/api/v1/cart/${supplierId}/saved`,
    getCheckoutInfo: (cartId) => `/api/v1/cart/${cartId}/checkout_info`,
    checkout: (cartId) => `/api/v1/cart/${cartId}/checkout`,
    removeCartSavedProduct: (cartId, productId) =>
      `/api/v1/cart/${cartId}/saved/${productId}`
  },
  product: {
    getProducts: '/api/v1/products',
    getProduct: (productId) => `/api/v1/products/${productId}`,
    getParentProduct: (parentProductId) =>
      `/api/v1/products/${parentProductId}/parent`,
    searchProducts: '/api/v1/products/autosuggest',
    getPopularProducts: '/api/v1/products/popular',
    getPopularCategories: '/api/v1/products/popular_categories',
    getFavoriteProducts: '/api/v1/products/favorites',
    updateProductFavorite: (productId) =>
      `/api/v1/products/${productId}/favorite`,
    getFilters: '/api/v1/products/filters',
    getPurchaseHistory: '/api/v1/products/purchase_history',
    getRecentlyViewed: '/api/v1/products/recently_viewed'
  },
  deals: {
    getDeals: '/api/v1/deals',
    getDealsGroup: '/api/v1/deals/group',
    commitToBuy: (dealId) => `/api/v1/deals/${dealId}/commit_to_buy`,
    getCartProducts: '/api/v1/deals/open_cart_products.json'
  },
  offer: {
    getOffers: '/api/v1/offers',
    submitOffer: '/api/v1/offers',
    verifyOffer: '/api/v1/offers/verify',
    getOffer: (offerId) => `/api/v1/offers/${offerId}`,
    counterOffer: (offerId) => `/api/v1/offers/${offerId}/counter`,
    cancelOffer: (offerId) => `/api/v1/offers/${offerId}/cancel`,
    respondOffer: (offerId) => `/api/v1/offers/${offerId}/respond`,
    sendReminder: (offerId) => `/api/v1/offers/${offerId}/send_reminder`,
    getBundleOffer: (offerId) => `/api/v1/bundle_offers/${offerId}`,
    respondBundleOffer: (offerId) => `/api/v1/bundle_offers/${offerId}/respond`,
    makeOffer: (offerId) => `/api/v1/bundle_offers/${offerId}/make_offer`
  },
  order: {
    getOrders: '/api/v1/orders.json',
    getOrder: (orderId) => `/api/v1/orders/${orderId}`,
    updateOrder: (orderId) => `/api/v1/orders/${orderId}/update`,
    getOrderLineItems: (orderId) => `/api/v1/orders/${orderId}/line_items`,
    fulfillOrder: (orderId) => `/api/v1/orders/${orderId}/fulfill`,
    cancelOrder: (orderId) => `/api/v1/orders/${orderId}/cancel`,
    searchOrders: '/api/v1/orders/autosuggest',
    getPurchasedItems: '/api/v1/orders/purchased_items.json',
    getEditOrder: (orderId) => `/api/v1/edit_order/${orderId}/show`,
    getPedigrees: '/api/v1/orders/pedigrees.json'
  },
  messages: {
    getMessages: '/api/v1/messages',
    updateMessages: '/api/v1/messages',
    getPrompts: '/api/v1/messages/prompts',
    getConversation: (conversationId) => `/api/v1/messages/${conversationId}`,
    updateConversation: (conversationId) =>
      `/api/v1/messages/${conversationId}`,
    closeConversation: (conversationId) =>
      `/api/v1/messages/${conversationId}/close`
  },
  user: {
    login: '/api/v1/users/login',
    getUser: '/api/v1/mobile/users/me',
    getUserStats: '/api/v1/users/me/stats',
    getSupplier: '/api/v1/mobile/supplier/me',
    subLogin: '/api/v1/sub_locations/login',
    signupNcpdp: '/api/v1/users/signup/ncpdp',
    getInviteInfo: '/api/v1/mobile/users/invite_info',
    inviteUsers: '/api/v1/users/invite',
    send2FactorOtp: '/api/v1/users/two_factor_authentication/send_otp',
    verify2FactorOtp: '/api/v1/users/two_factor_authentication/otp/verify',
    activate2Factor: '/api/v1/users/two_factor_authentication/activate',
    deactivate2Factor: '/api/v1/users/two_factor_authentication/deactivate',
    resetPassword: '/api/v1/users/reset/password/mobile',
    makeOffer: '/api/v1/users/offers/make_offer',
    getShippingMethods: (userId) =>
      `/api/v1/users/${userId}/shipping_methods.json`
  },
  pharmacy: {
    getShortDates: '/api/v1/pharmacy/shortdates'
  },
  wholesalers: {
    getWholesalers: '/api/v1/wholesalers.json',
    getWholesaler: (supplierId) => `/api/v1/wholesalers/${supplierId}.json`,
    getWholesalerDetails: (supplierId) => `/api/v1/wholesalers/${supplierId}`,
    getWholesalerStatus: (supplierId) =>
      `/api/v1/wholesalers/${supplierId}/status`,
    getWholesalerProducts: (supplierId) =>
      `/api/v1/wholesalers/${supplierId}/products`,
    getWholesalerNotices: (supplierId) =>
      `/api/v1/wholesalers/${supplierId}/notices.json`,
    getApplyWholesalers: '/api/v1/wholesalers/apply',
    getApplyWholesalerDetails: (supplierId) =>
      `/api/v1/wholesalers/${supplierId}/apply`,
    getWholesalerLinkAccount: (supplierId) =>
      `/api/v1/wholesalers/${supplierId}/link_account`,
    linkWholesalerAccount: (supplierId) =>
      `/api/v1/wholesalers/${supplierId}/link_account`,
    requestWholesalerCreditLimitIncrease: (supplierId) =>
      `/api/v1/wholesalers/${supplierId}/request_credit_increase`,
    getP2PSanctions: '/api/v1/wholesaler/p2p_sanctions',
    applyControls: (supplierId) =>
      `/api/v1/wholesalers/${supplierId}/apply_controls`,
    getShipping: (supplierId) => `/api/v1/wholesalers/${supplierId}/shipping`,
    getWholesalerAllProducts: (supplierId) =>
      `/api/v1/wholesalers/${supplierId}/all_products`,
    markWholesalerNoticeAsRead: (supplierId, noticeId) =>
      `/api/v1/wholesalers/${supplierId}/notices/${noticeId}/mark_as_read.json`,
    getWholesalerCart: (supplierId) => `/api/v1/wholesalers/${supplierId}/cart`
  },
  invoice: {
    getInvoices: '/api/v1/invoices.json',
    getInvoice: (invoiceId) => `/api/v1/invoices/${invoiceId}`,
    getPaymentMethods: (invoiceId) =>
      `/api/v1/invoices/${invoiceId}/payment_methods`,
    updateProcessPayment: (invoiceId) =>
      `/api/v1/invoices/${invoiceId}/process_payment`,
    paidOffline: (invoiceId) => `/api/v1/invoices/${invoiceId}/paid_offline`
  },
  supplier: {
    getOffers: '/api/v1/supplier/offers',
    getAccountSettings: '/api/v1/supplier/settings/account.json',
    updateAccountSettings: '/api/v1/supplier/settings/account.json',
    getSecuritySettings: '/api/v1/supplier/settings/security',
    updateSecuritySettings: '/api/v1/supplier/settings/security'
  },
  settings: {
    getAccount: '/api/v1/settings/account',
    updateAccount: '/api/v1/settings/account',
    getPreferences: '/api/v1/settings/preferences',
    updatePreferences: '/api/v1/settings/preferences',
    getSecurity: 'api/v1/settings/security',
    updateSecurity: '/api/v1/settings/security'
  },
  helloSign: {
    getSupplierHelloSign: (supplierId) => `/api/v1/hellosign_url/${supplierId}`
  },
  adCards: {
    getAdCards: '/api/v1/ad_cards'
  },
  billing: {
    getBanks: '/api/v1/billing/banks',
    updateBanks: '/api/v1/billing/banks',
    updateDefaultBank: (bankId) => `/api/v1/billing/banks/${bankId}/default`,
    removeBank: (bankId) => `/api/v1/billing/banks/${bankId}`,
    getCreditCards: '/api/v1/billing/credit_cards',
    updateCreditCards: '/api/v1/billing/credit_cards',
    removeCreditCard: (cardId) => `/api/v1/billing/credit_cards/${cardId}`,
    updateDefaultCreditCard: (cardId) =>
      `/api/v1/billing/credit_cards/${cardId}/default`,
    getCredits: '/api/v1/billing/credits'
  },
  shipping: {
    getTimeInTransit: '/api/v1/shipping/time_in_transit'
  },
  customers: {
    getCustomer: (customerId) => `/api/v1/customers/${customerId}`
  },
  inventory: {
    getInventory: '/api/v1/inventory',
    searchInventory: '/api/v1/inventory/autosuggest',
    updateProductInventory: (productId) => `/api/v1/inventory/${productId}`
  },
  unreadRecords: {
    getUnreadRecordsCount: '/api/v1/unread_records_count'
  },
  other: {
    getWebService: '/api/v1/web_service',
    getWebServiceProductInfo: '/api/v1/web_service/order_products_info',
    updateScannedResults: '/api/v1/scanned_results',
    switchLocation: '/api/v1/sub_locations',
    customerSellingProducts: '/api/v1/customers_selling_product'
  },
  notification: {
    getNotifications: '/api/v1/notifications',
    readNotifications: '/api/v1/notifications/read'
  }
}
