import React, { useEffect } from 'react'
import ReactGA from 'react-ga4'

ReactGA.initialize([{ trackingId: process.env.RAZZLE_GOOGLE_TRACKING_CODE }])

export const GATracker = (WrappedComponent) => {
  const trackPage = (page) => {
    ReactGA.set({ page })
    ReactGA.send({ hitType: 'pageview', page })
  }

  const HOC = (props) => {
    const { location } = props
    useEffect(() => {
      trackPage(location.pathname + location.search)
    }, [location.pathname])
    return <WrappedComponent {...props} />
  }

  return HOC
}
