import dayjs from 'dayjs'
import {
  FETCHING_PROMPTS,
  FETCHING_PROMPTS_FAILURE,
  RECEIVED_PROMPTS,
  CREATING_CONVERSATION,
  RESET_SELECTED_DATA,
  CREATING_CONVERSATION_FAILURE,
  CREATED_CONVERSATION,
  FETCHING_CONVERSATIONS,
  FETCHING_CONVERSATIONS_FAILURE,
  RECEIVED_CONVERSATIONS,
  FETCHING_CONVERSATION_CONTEXT,
  RECEIVED_CONVERSATION_CONTEXT,
  FETCHING_CONVERSATION_CONTEXT_FAILURE,
  RELOADING_CONVERSATIONS,
  SELECTED_CONVERSATION,
  REFRESHING_CONVERSATION_MESSAGES,
  UPDATING_CONVERSATION,
  CLOSED_CONVERSATION,
  SENDING_MESSAGE,
  SENDING_MESSAGE_FAILURE,
  UPDATING_MESSAGE,
  HIDE_LOADING_CONVO
} from '../actions/messages'

const initialState = {
  loading: true,
  convosListLoading: false,
  convoLoading: true,
  convoContextLoading: false,
  submitting: false,
  prompts: [],
  conversations: [],
  selectedConversation: [],
  conversationContext: [],
  conversationMessages: {},
  latestMessages: {}
}

const messagesMap = (messages) => {
  return messages.map((message) => {
    const body = message.prompt ? message.prompt.body : message.body
    return {
      body: body,
      senderUserId: message.sender_user_id,
      conversationId: message.conversation_id,
      unread: message.unread,
      readAt: message.read_at,
      createdAt: message.created_at
    }
  })
}

const placeUpdatedConversationAboveOthers = (conversations, currentConvoId) => {
  const currentConvo = conversations.find(
    (convo) => convo.id === currentConvoId
  )
  if (!currentConvo) {
    return conversations
  }
  const conversationsExcludingCurrentConvo = conversations.filter(
    (convo) => convo.id !== currentConvoId
  )
  conversationsExcludingCurrentConvo.unshift(currentConvo)
  return conversationsExcludingCurrentConvo
}

const removeLastMessage = (conversations, convoId) => {
  const messages = conversations[convoId]
  messages.pop()
  const newMessages = {}
  newMessages[convoId] = messages
  return newMessages
}

const updateReadMessages = (state, message) => {
  const convoId = message.conversationId || message.conversation_id
  const conversations = state.conversationMessages
  let messages = conversations[convoId]
  let refreshConvoList = state.conversations
  let refreshLatestMsgs = state.latestMessages
  if (!messages || convoId !== state.selectedConversation.id) {
    return {
      newMessages: conversations,
      refreshConvoList: refreshConvoList,
      refreshLatestMsgs: refreshLatestMsgs
    }
  }
  if (message.newlyCreated) {
    messages = [...messages, message]
    refreshConvoList = placeUpdatedConversationAboveOthers(
      refreshConvoList,
      message.conversationId
    )
    refreshLatestMsgs[convoId] = { latestMessage: message }
  }
  messages.map((msg) => {
    msg.unread = false
    if (!msg.readAt) msg.readAt = dayjs().format()
  })
  const newMessages = new Object()
  newMessages[convoId] = messages
  return {
    newMessages,
    refreshConvoList,
    refreshLatestMsgs
  }
}

const fetchUpdatedConvo = (convoId, oldMessages, newMsg) => {
  const currentConvoMsgs = oldMessages[convoId]
  let updatedMessages = {}
  if (currentConvoMsgs) {
    updatedMessages[convoId] = [...currentConvoMsgs, newMsg]
  } else {
    updatedMessages = currentConvoMsgs
  }
  return updatedMessages
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_PROMPTS:
      return {
        ...state,
        loading: true
      }

    case FETCHING_PROMPTS_FAILURE:
      return {
        ...state,
        loading: false
      }

    case RECEIVED_PROMPTS:
      return {
        ...state,
        loading: false,
        prompts: action.data
      }

    case CREATING_CONVERSATION:
      return {
        ...state,
        submitting: true
      }

    case CREATING_CONVERSATION_FAILURE:
      return {
        ...state,
        submitting: false
      }

    case CREATED_CONVERSATION:
      return {
        ...state,
        submitting: false
      }

    case FETCHING_CONVERSATIONS:
      return {
        ...state,
        loading: true
      }

    case FETCHING_CONVERSATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        convosListLoading: false
      }

    case RECEIVED_CONVERSATIONS:
      const latestMessages = action.data.reduce((itemObj, convo) => {
        itemObj[convo.id] = { latestMessage: convo.messages[0] }
        return itemObj
      }, {})
      return {
        ...state,
        loading: false,
        convosListLoading: false,
        conversations: action.data,
        latestMessages: latestMessages
      }

    case FETCHING_CONVERSATION_CONTEXT:
      return {
        ...state,
        convoContextLoading: true,
        conversationContext: []
      }

    case RECEIVED_CONVERSATION_CONTEXT:
      return {
        ...state,
        convoContextLoading: false,
        conversationContext: action.payload
      }

    case FETCHING_CONVERSATION_CONTEXT_FAILURE:
      return {
        ...state,
        convoContextLoading: false
      }

    case RELOADING_CONVERSATIONS:
      return {
        ...state,
        convosListLoading: true
      }

    case RESET_SELECTED_DATA:
    	return {
    		...state,
    		convoLoading: true,
    		convoContextLoading: true,
    		selectedConversation: [],
    		conversationContext: []
    	}

    case SELECTED_CONVERSATION:
      const selectedConvoMessages = messagesMap(
        action.selectedConversation.messages
      )
      state.conversationMessages[
        action.selectedConversation.id
      ] = selectedConvoMessages
      return {
        ...state,
        convoLoading: true,
        selectedConversation: action.selectedConversation,
        conversationMessages: state.conversationMessages
      }

    case REFRESHING_CONVERSATION_MESSAGES:
      const updatedConvoMessages = messagesMap(action.data.messages)
      state.conversationMessages[action.convoId] = updatedConvoMessages
      state.latestMessages[action.convoId] = {
        latestMessage: updatedConvoMessages.slice(-1)[0]
      }
      return {
        ...state,
        convoLoading: false,
        conversationMessages: state.conversationMessages
      }

    case UPDATING_CONVERSATION:
      return {
        ...state,
        selectedConversation: action.conversation
      }

    case CLOSED_CONVERSATION:
      return {
        ...state,
        selectedConversation: action.selectedConversation
      }

    case SENDING_MESSAGE:
      state.latestMessages[action.message.conversationId] = {
        latestMessage: action.message
      }
      const refreshedConvos = placeUpdatedConversationAboveOthers(
        state.conversations,
        action.message.conversationId
      )
      return {
        ...state,
        conversationMessages: fetchUpdatedConvo(
          action.message.conversationId,
          state.conversationMessages,
          action.message
        ),
        latestMessages: state.latestMessages,
        conversations: refreshedConvos
      }

    case SENDING_MESSAGE_FAILURE:
      return {
        ...state,
        conversationMessages: removeLastMessage(
          state.conversationMessages,
          action.message.conversationId
        )
      }

    case UPDATING_MESSAGE:
      const fetchUpdatedLists = updateReadMessages(state, action.message)
      return {
        ...state,
        conversationMessages: fetchUpdatedLists.newMessages,
        latestMessages: fetchUpdatedLists.refreshLatestMsgs,
        conversations: fetchUpdatedLists.refreshConvoList
      }
    case HIDE_LOADING_CONVO:
      return {
        ...state,
        convoLoading: false
      }

    default:
      return state
  }
}
