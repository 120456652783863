import {
  FETCHING_CART,
  CHANGE_QTY,
  FETCHING_CART_FAILURE,
  RECEIVED_CART,
  RECEIVED_SUGGESTIONS,
  SWAPPING_ITEM,
  ITEM_SWAPPED,
  ADDED_TO_CART,
  ADD_TO_CART_FAILURE,
  ADDING_PRODUCT,
  UPDATING_QTY,
  UPDATING_QTY_FAILURE,
  READDING_LINE_ITEM,
  READDING_LINE_ITEM_FAILURE,
  REMOVING_LINE_ITEM,
  REMOVE_LINE_ITEM_FAILURE,
  REMOVED_LINE_ITEM,
  SET_CARTS_TOTAL,
  LOADING_ITEMS_FAILURE,
  LOADING_ITEMS,
  RESET_LOADING_ITEMS,
  LOADED_ITEMS,
  DISABLE_ALL_APPLY_CONTROL_BTN,
  ENABLE_APPLY_CONTROL_BTN,
  REFRESH_LIVE_UPDATES
} from '../actions/cart'
import _isEmpty from 'lodash.isempty'

const initialState = {
  loading: false,
  adding: false,
  animateHeaderSummary: false,
  cart: [],
  cartLineItemsUpdating: {},
  cartLineItems: {},
  hasMorePages: {},
  itemsLoading: {},
  cartLineItemsTotal: {},
  cartLineItemsQty: {},
  cartLineItemsSoftDeleted: {},
  cartsTotal: 0,
  cartSuggestions: {},
  reloadSuggestions: {},
  controlBtnMap: {},
  totalItemsCount: 0,
  updatingVariantId: 0,
  qtyByVariantId: {},
  qty: 1,
  disableAllApplyControlBtn: false,
  externalWebServiceAvailable: false
}

const UPDATING_STATUS = {
  updating: 'updating',
  failed: 'failed'
}

const updateCart = (state, action, status) => {
  const { totalItemsCount, cartsTotal, cartLineItemsTotal } = state
  let qtyDiff = parseInt(action.updatedQty) - parseInt(action.oldQty)
  qtyDiff = status === UPDATING_STATUS.failed ? -qtyDiff : qtyDiff
  const totalItem = totalItemsCount + qtyDiff
  const cartTotal =
    parseFloat(cartsTotal) +
    parseFloat(parseFloat(action.lineItem.price) * qtyDiff)
  const orderTotal = cartLineItemsTotal[action.cartId]
  const lineItemsTotal =
    parseFloat(orderTotal.total) + parseFloat(action.lineItem.price) * qtyDiff

  return {
    totalItem,
    cartTotal: parseFloat(cartTotal.toFixed(2)),
    lineItemsTotal: parseFloat(lineItemsTotal.toFixed(2)),
    lineItemsCount: orderTotal.totalItems + qtyDiff
  }
}

const fetchItemsLoadingState = (state, cartId) => {
  return { [cartId]: { loading: state } }
}

const updateOldCartAfterSwap = (state, action) => {
  const { cartLineItems, cartLineItemsTotal } = state
  const lineItem = action.payload.original_line_item
  const cartItems = cartLineItems[action.oldCartId].filter(
    (item) => item.id !== lineItem.id
  )
  const cartTotalObj = cartLineItemsTotal[action.oldCartId]

  return {
    items: cartItems,
    oldCartTotal:
      cartTotalObj.total - parseFloat(lineItem.price * action.quantity),
    totalItems: cartTotalObj.totalItems - action.quantity
  }
}

const fetchNewLineItem = (state, action) => {
  const { cartLineItems, cartLineItemsTotal } = state
  const cartItems = cartLineItems[action.cartId]
  const currentCartTotalObj = cartLineItemsTotal[action.cartId]
  const lineItem = action.payload.line_item
  return {
    newItems: [lineItem, ...cartItems],
    newTotalObj: {
      total:
        currentCartTotalObj.total +
        parseFloat(lineItem.price * action.newItemQty),
      totalItems: currentCartTotalObj.totalItems + action.newItemQty
    }
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_QTY: {
      return {
        ...state,
        qtyByVariantId: {
          ...state.qtyByVariantId,
          [action.variantId]: action.qty
        }
      }
    }

    case SET_CARTS_TOTAL: {
      return {
        ...state,
        cartsTotal: action.cartsTotal,
        totalItemsCount: action.totalItemsCount
      }
    }
    case ADDING_PRODUCT: {
      return {
        ...state,
        updatingVariantId: action.variantId,
        animateHeaderSummary: true,
        adding: true
      }
    }
    case ADDED_TO_CART: {
      let {
        cartLineItems,
        cartLineItemsTotal,
        qtyByVariantId,
        totalItemsCount,
        reloadSuggestions,
        cartsTotal
      } = state
      qtyByVariantId = { ...qtyByVariantId, [action.variantId]: 1 }
      reloadSuggestions = { ...reloadSuggestions, [action.cartId]: true }
      if (action.cartId) {
        const updatedCart = fetchNewLineItem(state, action)
        cartLineItems = {
          ...cartLineItems,
          [action.cartId]: updatedCart.newItems
        }
        cartLineItemsTotal = {
          ...cartLineItemsTotal,
          [action.cartId]: updatedCart.newTotalObj
        }
      }
      const updateCounterBy = action.payload.already_added ? 0 : 1
      totalItemsCount += updateCounterBy
      cartsTotal += parseFloat(action.payload.line_item_price)
      return {
        ...state,
        cartsTotal,
        totalItemsCount,
        updatingVariantId: 0,
        qtyByVariantId,
        cartLineItems,
        cartLineItemsTotal,
        animateHeaderSummary: false,
        adding: false,
        reloadSuggestions
      }
    }

    case ADD_TO_CART_FAILURE: {
      return {
        ...state,
        updatingVariantId: 0,
        qtyByVariantId: { ...state.qtyByVariantId, [action.variantId]: 1 },
        adding: false
      }
    }
    case DISABLE_ALL_APPLY_CONTROL_BTN: {
      return {
        ...state,
        disableAllApplyControlBtn: true,
        controlBtnMap: {
          ...state.controlBtnMap,
          [action.supplierId]: {
            disableAllApplyControlBtn: true
          }
        }
      }
    }

    case UPDATING_QTY: {
      let { cartLineItemsTotal, cartLineItemsQty } = state
      const updatedCart = updateCart(state, action, UPDATING_STATUS.updating)
      cartLineItemsQty = {
        ...cartLineItemsQty,
        [action.lineItem.id]: action.updatedQty
      }
      cartLineItemsTotal = {
        ...cartLineItemsTotal,
        [action.cartId]: {
          total: updatedCart.lineItemsTotal,
          totalItems: updatedCart.lineItemsCount
        }
      }
      return {
        ...state,
        cartLineItemsQty,
        cartsTotal: updatedCart.cartTotal,
        cartLineItemsTotal
      }
    }

    case UPDATING_QTY_FAILURE: {
      let { cartLineItemsTotal, cartLineItemsQty } = state
      const updatedFailedCart = updateCart(
        state,
        action,
        UPDATING_STATUS.failed
      )
      cartLineItemsTotal = {
        ...cartLineItemsTotal,
        [action.cartId]: {
          total: updatedFailedCart.lineItemsTotal,
          totalItems: updatedFailedCart.lineItemsCount
        }
      }
      cartLineItemsQty = {
        ...cartLineItemsQty,
        [action.lineItem.id]: action.oldQty
      }
      return {
        ...state,
        cartsTotal: updatedFailedCart.cartTotal,
        cartLineItemsQty,
        cartLineItemsTotal
      }
    }

    case READDING_LINE_ITEM: {
      let {
        cartLineItemsTotal,
        cartLineItemsQty,
        cartLineItemsSoftDeleted,
        totalItemsCount
      } = state
      const updatedCart = updateCart(state, action, UPDATING_STATUS.updating)
      cartLineItemsTotal = {
        ...cartLineItemsTotal,
        [action.cartId]: {
          total: updatedCart.lineItemsTotal,
          totalItems: updatedCart.lineItemsCount
        }
      }
      cartLineItemsQty = {
        ...cartLineItemsQty,
        [action.lineItem.id]: action.updatedQty
      }
      cartLineItemsSoftDeleted = {
        ...cartLineItemsSoftDeleted,
        [action.lineItem.id]: false
      }
      totalItemsCount += 1
      return {
        ...state,
        cartLineItemsQty,
        cartLineItemsSoftDeleted,
        totalItemsCount,
        cartsTotal: updatedCart.cartTotal,
        cartLineItemsTotal
      }
    }

    case READDING_LINE_ITEM_FAILURE: {
      let {
        cartLineItemsTotal,
        cartLineItemsQty,
        cartLineItemsSoftDeleted,
        totalItemsCount
      } = state
      const updatedFailedCart = updateCart(
        state,
        action,
        UPDATING_STATUS.failed
      )
      cartLineItemsTotal = {
        ...cartLineItemsTotal,
        [action.cartId]: {
          total: updatedFailedCart.lineItemsTotal,
          totalItems: updatedFailedCart.lineItemsCount
        }
      }
      cartLineItemsQty = {
        ...cartLineItemsQty,
        [action.lineItem.id]: action.oldQty
      }
      cartLineItemsSoftDeleted = {
        ...cartLineItemsSoftDeleted,
        [action.lineItem.id]: true
      }
      totalItemsCount -= 1
      return {
        ...state,
        totalItemsCount,
        cartsTotal: updatedFailedCart.cartTotal,
        cartLineItemsQty,
        cartLineItemsSoftDeleted,
        cartLineItemsTotal
      }
    }

    case REMOVING_LINE_ITEM: {
      let {
        cartLineItems,
        cartLineItemsTotal,
        reloadSuggestions,
        totalItemsCount,
        cartsTotal
      } = state
      const cartRecords = cartLineItems[action.cartId]
      const cartLineItemsObj = cartLineItemsTotal[action.cartId]
      const { lineItem } = action
      cartLineItems = {
        ...cartLineItems,
        [action.cartId]: cartRecords.filter((item) => item.id !== lineItem.id)
      }
      cartLineItemsTotal = {
        ...cartLineItemsTotal,
        [action.cartId]: {
          total:
            cartLineItemsObj.total -
            parseFloat(lineItem.price * action.updatedQty),
          totalItems: cartLineItemsObj.totalItems - action.updatedQty
        }
      }
      reloadSuggestions = {
        ...reloadSuggestions,
        [action.cartId]: true
      }
      totalItemsCount -= 1
      cartsTotal -= parseFloat(lineItem.price * action.updatedQty)

      return {
        ...state,
        cartsTotal,
        totalItemsCount,
        cartLineItems,
        cartLineItemsTotal,
        reloadSuggestions
      }
    }

    case REMOVE_LINE_ITEM_FAILURE: {
      let {
        cartLineItems,
        cartLineItemsTotal,
        cartsTotal,
        totalItemsCount
      } = state
      const newItems = cartLineItems[action.cartId]
      const currentTotalObj = cartLineItemsTotal[action.cartId]
      cartLineItems = {
        ...cartLineItems,
        [action.cartId]: [action.lineItem, ...newItems]
      }
      cartLineItemsTotal = {
        ...cartLineItemsTotal,
        [action.cartId]: {
          total:
            currentTotalObj.total +
            parseFloat(action.lineItem.price * action.updatedQty),
          totalItems: currentTotalObj.totalItems + action.updatedQty
        }
      }
      cartsTotal += parseFloat(action.lineItem.price * action.updatedQty)
      totalItemsCount += 1

      return {
        ...state,
        cartsTotal,
        totalItemsCount,
        cartLineItems,
        cartLineItemsTotal
      }
    }

    case REMOVED_LINE_ITEM: {
      return {
        ...state,
        cartLineItemsTotal: {
          ...state.cartLineItemsTotal,
          [action.cartId]: {
            total: parseFloat(action.itemTotal),
            totalItems: action.itemCount
          }
        }
      }
    }

    case FETCHING_CART: {
      return {
        ...state,
        loading: true
      }
    }

    case FETCHING_CART_FAILURE: {
      return {
        ...state,
        loading: false
      }
    }

    case ENABLE_APPLY_CONTROL_BTN: {
      return {
        ...state,
        controlBtnMap: {
          ...state.controlBtnMap,
          [action.supplierId]: {
            disableAllApplyControlBtn: false
          }
        }
      }
    }

    case RECEIVED_CART: {
      const itemsTotal = {}
      const controlBtnMap = {}
      if (action.singleCart) {
        controlBtnMap[action.payload.supplier_id] = {
          disableAllApplyControlBtn: false
        }
        itemsTotal[action.payload.id] = {
          total: parseFloat(action.payload.item_total),
          totalItems: action.payload.item_count
        }
      } else {
        action.payload.forEach((element) => {
          controlBtnMap[element.supplier_id] = {
            disableAllApplyControlBtn: false
          }
          itemsTotal[element.id] = {
            total: parseFloat(element.item_total),
            totalItems: element.item_count
          }
        })
      }

      return {
        ...state,
        loading: false,
        cart: action.payload,
        controlBtnMap,
        cartLineItemsTotal: itemsTotal
      }
    }

    case LOADING_ITEMS: {
      return {
        ...state,
        itemsLoading: {
          ...state.itemsLoading,
          ...fetchItemsLoadingState(true, action.cartId)
        }
      }
    }

    case RESET_LOADING_ITEMS: {
      return {
        ...state,
        itemsLoading: {}
      }
    }

    case LOADING_ITEMS_FAILURE: {
      return {
        ...state,
        itemsLoading: {
          ...state.itemsLoading,
          ...fetchItemsLoadingState(false, action.cartId)
        }
      }
    }

    case LOADED_ITEMS: {
      const { cartLineItems, hasMorePages, itemsLoading } = state
      let oldItems = cartLineItems[action.cartId]
      oldItems = !_isEmpty(oldItems) && !action.reset ? oldItems : []

      const items = { [action.cartId]: oldItems.concat(action.payload) }
      const cartMorePages = {
        [action.cartId]: action.hasMore
          ? {
              ...itemsLoading,
              ...fetchItemsLoadingState(false, action.cartId)
            }
          : itemsLoading
      }
      return {
        ...state,
        cartLineItems: { ...cartLineItems, ...items },
        hasMorePages: { ...hasMorePages, ...cartMorePages },
        itemsLoading: action.showLoading
      }
    }

    case RECEIVED_SUGGESTIONS: {
      return {
        ...state,
        cartSuggestions: {
          ...state.cartSuggestions,
          [action.cartId]: action.payload
        },
        reloadSuggestions: {
          ...state.reloadSuggestions,
          [action.cartId]: false
        }
      }
    }

    case SWAPPING_ITEM: {
      let { cartSuggestions } = state
      const oldRecords = cartSuggestions[action.cartId]
      cartSuggestions = {
        ...cartSuggestions,
        [action.cartId]: oldRecords.filter(
          (item) => item.line_item.id !== action.suggestion.line_item.id
        )
      }
      return {
        ...state,
        cartSuggestions
      }
    }

    case ITEM_SWAPPED: {
      let {
        cartLineItems,
        cartLineItemsTotal,
        cartsTotal,
        cartLineItemsQty,
        cartLineItemsSoftDeleted,
        totalItemsCount
      } = state
      const { oldCartId } = action
      const lineItem = action.payload.line_item
      const newCartId = action.suggestion.new_cart_id
      const newCartTotalObj = cartLineItemsTotal[newCartId]
      const newCartItems = cartLineItems[newCartId]
      const updatedOldCartItems = updateOldCartAfterSwap(state, action)

      let lineItems = newCartItems
      const presentItem = newCartItems.find((item) => item.id === lineItem.id)
      if (presentItem) {
        cartLineItemsQty = {
          ...cartLineItemsQty,
          [lineItem.id]: presentItem.quantity + action.quantity
        }
        cartLineItemsSoftDeleted = {
          ...cartLineItemsSoftDeleted,
          [lineItem.id]: false
        }
        totalItemsCount -= 1
      } else {
        lineItems = [lineItem, ...newCartItems]
      }

      cartsTotal +=
        parseFloat(lineItem.price) -
        parseFloat(action.payload.original_line_item.price)

      cartLineItems = {
        ...cartLineItems,
        [oldCartId]: updatedOldCartItems.items,
        [newCartId]: lineItems
      }
      cartLineItemsTotal = {
        ...cartLineItemsTotal,
        [oldCartId]: {
          total: updatedOldCartItems.oldCartTotal,
          totalItems: updatedOldCartItems.totalItems
        },
        [newCartId]: {
          total:
            newCartTotalObj.total +
            parseFloat(lineItem.price * action.quantity),
          totalItems: newCartTotalObj.totalItems + action.quantity
        }
      }

      return {
        ...state,
        cartsTotal,
        totalItemsCount,
        cartLineItems,
        cartLineItemsTotal,
        cartLineItemsQty,
        cartLineItemsSoftDeleted
      }
    }

    case REFRESH_LIVE_UPDATES: {
      let {
        cartLineItems,
        cartLineItemsTotal,
        cartLineItemsQty,
        cartLineItemsSoftDeleted
      } = state
      cartLineItems = {
        ...cartLineItems,
        [action.cartId]: action.payload.items
      }

      cartLineItemsTotal = {
        ...cartLineItemsTotal,
        [action.cartId]: {
          total: parseFloat(action.payload.item_total),
          totalItems: action.payload.item_count
        }
      }
      action.payload.items.forEach((element) => {
        cartLineItemsQty = {
          ...cartLineItemsQty,
          [element.id]: element.quantity
        }
        cartLineItemsSoftDeleted = {
          ...cartLineItemsSoftDeleted,
          [element.id]: element.soft_deleted
        }
      })

      return {
        ...state,
        cartLineItems,
        cartLineItemsTotal,
        cartLineItemsQty,
        cartLineItemsSoftDeleted
      }
    }

    default:
      return state
  }
}
