import { api } from '../axios'
import { apiUrls } from '../helpers/apiHelper'

export const RETRIEVING_ORDERS = 'orders/RETRIEVING_ORDERS'
export const RETRIEVED_ORDERS = 'orders/RETRIEVED_ORDERS'
export const RETRIEVING_ORDERS_FAILURE = 'orders/RETRIEVING_ORDERS_FAILURE'

export const RETRIEVING_ORDER_METADATA = 'orders/RETRIEVING_ORDER_METADATA'
export const RETRIEVED_ORDER_METADATA = 'orders/RETRIEVED_ORDER_METADATA'
export const RETRIEVING_ORDER_METADATA_FAILURE =
  'orders/RETRIEVING_ORDER_METADATA_FAILURE'
export const RETRIEVING_ORDER_LINE_ITEMS = 'orders/RETRIEVING_ORDER_LINE_ITEMS'
export const RETRIEVED_ORDER_LINE_ITEMS = 'orders/RETRIEVED_ORDER_LINE_ITEMS'
export const RETRIEVING_ORDER_LINE_ITEMS_FAILURE =
  'orders/RETRIEVING_ORDER_LINE_ITEMS_FAILURE'

export const FULFILLING_ORDER = 'orders/FULFILLING_ORDER'
export const FULFILLED_ORDER = 'orders/FULFILLED_ORDER'
export const FULFILLING_ORDER_FAILURE = 'orders/FULFILLING_ORDER_FAILURE'

export const getOrders = (page, filterParams = {}) => {
  return (dispatch) => {
    dispatch({
      type: RETRIEVING_ORDERS,
      currentPage: page
    })

    let params = {
      page,
      per_page: 10,
      from_reports: true
    }
    if (Object.keys(filterParams).length > 0) {
      params = { ...params, ...filterParams }
    }

    return api
      .get(apiUrls.order.getOrders, {
        params
      })
      .then((response) => {
        dispatch({
          type: RETRIEVED_ORDERS,
          payload: response.data,
          currentPage: page
        })
      })
      .catch((e) => {
        dispatch({
          type: RETRIEVING_ORDERS_FAILURE
        })
        console.error(e)
      })
  }
}

export const getRpH2RpHordersReceived = (page) => {
  return (dispatch) => {
    dispatch({
      type: RETRIEVING_ORDERS,
      currentPage: page
    })

    return api
      .get(apiUrls.order.getOrders, {
        params: {
          p2p_orders_only: true,
          page,
          per_page: 10
        }
      })
      .then((response) => {
        dispatch({
          type: RETRIEVED_ORDERS,
          payload: response.data,
          currentPage: page
        })
      })
      .catch((e) => {
        dispatch({
          type: RETRIEVING_ORDERS_FAILURE
        })
        console.error(e)
      })
  }
}

export const getOrderMetadata = (orderId) => {
  return (dispatch) => {
    dispatch({
      type: RETRIEVING_ORDER_METADATA
    })

    return api
      .get(apiUrls.order.getOrder(orderId))
      .then((response) => {
        dispatch({
          type: RETRIEVED_ORDER_METADATA,
          payload: response.data
        })
      })
      .catch((e) => {
        dispatch({
          type: RETRIEVING_ORDER_METADATA_FAILURE
        })
        console.error(e)
      })
  }
}

export const getOrderLineItems = (orderId) => {
  return (dispatch) => {
    dispatch({
      type: RETRIEVING_ORDER_LINE_ITEMS
    })

    return api
      .get(apiUrls.order.getOrderLineItems(orderId))
      .then((response) => {
        dispatch({
          type: RETRIEVED_ORDER_LINE_ITEMS,
          payload: response.data
        })
      })
      .catch((e) => {
        dispatch({
          type: RETRIEVING_ORDER_LINE_ITEMS_FAILURE
        })
        console.error(e)
      })
  }
}

export const fulfillOrder = (
  orderId,
  params = {},
  actionCallback = () => {}
) => {
  return (dispatch) => {
    dispatch({
      type: FULFILLING_ORDER
    })

    const { trackingNumber } = params

    return api
      .post(apiUrls.order.fulfillOrder(orderId), {
        tracking_number: trackingNumber
      })
      .then((response) => {
        dispatch({
          type: FULFILLED_ORDER,
          payload: response.data
        })
        actionCallback()
      })
      .catch((e) => {
        dispatch({
          type: FULFILLING_ORDER_FAILURE
        })
        console.error(e)
        actionCallback()
      })
  }
}
