import { api } from '../axios'
import { apiUrls } from '../helpers/apiHelper'

export const PERFORMING_PRODUCT_SEARCH = 'search/PERFORMING_PRODUCT_SEARCH'
export const PERFORMED_PRODUCT_SEARCH = 'search/PERFORMED_PRODUCT_SEARCH'
export const PERFORMING_PRODUCT_SEARCH_FAILURE =
  'search/PERFORMING_PRODUCT_SEARCH_FAILURE'

export const performProductSearch = (query, limit = 10, wholesalerId = 0) => {
  return (dispatch) => {
    dispatch({
      type: PERFORMING_PRODUCT_SEARCH
    })

    return api
      .get(apiUrls.product.searchProducts, {
        params: {
          input: query,
          wholesaler_id: wholesalerId,
          limit: limit
        }
      })
      .then((response) => {
        dispatch({
          type: PERFORMED_PRODUCT_SEARCH,
          payload: response.data
        })
      })
      .catch((e) => {
        dispatch({
          type: PERFORMING_PRODUCT_SEARCH_FAILURE
        })
      })
  }
}
