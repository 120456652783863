import React, { useEffect } from 'react'
import LinkedInTag from 'react-linkedin-insight'

const SUB_DOMAIN = 'px'

export const LinkedinTracker = (WrappedComponent) => {
  return (props) => {
    useEffect(() => {
      LinkedInTag.init(process.env.RAZZLE_LINKEDIN_PARTNER_ID, SUB_DOMAIN)
    }, [])

    return <WrappedComponent {...props} />
  }
}
