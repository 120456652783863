import { publicApi, api, clientCredentials } from '../axios'
import { apiUrls } from '../helpers/apiHelper'
import {
  fbPixelTrack,
  events as fbEvents
} from '../helpers/facebookPixelHelper'
import { ROLE } from '../helpers/userHelper'

export const RETRIEVING_NCPDP_DATA = 'user/RETRIEVING_NCPDP_DATA'
export const RETRIEVED_NCPDP_DATA = 'user/RETRIEVED_NCPDP_DATA'
export const RETRIEVING_NCPDP_DATA_FAILURE =
  'user/RETRIEVING_NCPDP_DATA_FAILURE'

export const LOGGING_IN = 'user/LOGGING_IN'
export const LOGGED_IN = 'user/LOGGED_IN'
export const LOGGING_IN_FAILURE = 'user/LOGGING_IN_FAILURE'
export const REFRESH_USER_DATA = 'user/REFRESH_USER_DATA'
export const REFRESH_DATA_FAILED = 'user/REFRESH_DATA_FAILED'

export const UNAUTHORIZED_AUTH = 'user/UNAUTHORIZED_AUTH'
export const RESET_ERROR_MSG = 'user/RESET_ERROR_MSG'
export const RESET_LOGIN = 'user/RESET_LOGIN'
export const LOG_OUT = 'user/LOG_OUT'
export const VERIFIED_OTP = 'user/VERIFIED_OTP'

export const login = (deaNumber, password, history) => {
  let isOtpEnabled = false
  let user = {}
  return (dispatch) => {
    dispatch({
      type: LOGGING_IN
    })

    return publicApi
      .post(apiUrls.user.login, {
        mobile_client: true,
        dea_number: deaNumber,
        password
      })
      .then((response) => {
        fbPixelTrack(fbEvents.login)
        if (response.data.user.show_supplier_page) {
          api.get(apiUrls.user.getSupplier).then((res) => {
            user = res.data.user
            dispatch({
              type: LOGGED_IN,
              payload: {
                ...user,
                role: ROLE.wholesaler
              },
              otpEnabled: false
            })
          })
        } else {
          user = response.data.user
          isOtpEnabled = user.otp_required_for_login
          dispatch({
            type: LOGGED_IN,
            payload: { ...user, role: ROLE.pharmacy },
            otpEnabled: isOtpEnabled
          })
        }
      })
      .then(() => {
        if (isOtpEnabled) {
          history.push('login/otp', user.mfa_setting)
        }
      })
      .catch((e) => {
        let error = ''
        if (e.response && e.response.data) {
          error = e.response.data.message
        }
        dispatch({
          type: LOGGING_IN_FAILURE,
          error
        })

        setTimeout(() => {
          dispatch({
            type: RESET_LOGIN
          })
        }, 3000)
      })
  }
}

export const subUserLogin = (subUserId) => {
  let user = {}
  return (dispatch) => {
    dispatch({
      type: LOGGING_IN
    })

    return api
      .post(apiUrls.user.subLogin, {
        sub_user_id: subUserId
      })
      .then((response) => {
        user = response.data.user
        dispatch({
          type: LOGGED_IN,
          payload: user
        })
      })
      .catch((e) => {
        let error = ''
        if (e.response && e.response.data) {
          error = e.response.data.message
        }
        dispatch({
          type: LOGGING_IN_FAILURE,
          error
        })
      })
  }
}

export const backToAccount = (masterUserId) => {
  let user = {}
  return (dispatch) => {
    dispatch({
      type: LOGGING_IN
    })

    return api
      .post(apiUrls.user.subLogin, {
        master_user_id: masterUserId
      })
      .then((response) => {
        user = response.data.user
        dispatch({
          type: LOGGED_IN,
          payload: user
        })
      })
      .catch((e) => {
        let error = ''
        if (e.response && e.response.data) {
          error = e.response.data.message
        }
        dispatch({
          type: LOGGING_IN_FAILURE,
          error
        })
      })
  }
}

export const refreshUserData = () => {
  return (dispatch) => {
    return api
      .get(apiUrls.user.getUser, {})
      .then((response) => {
        if (response.data.show_supplier_page) {
          api.get(apiUrls.user.getSupplier).then((res) => {
            dispatch({
              type: REFRESH_USER_DATA,
              freshUserData: {
                ...res.data.user,
                role: ROLE.wholesaler
              }
            })
          })
          return
        }
        dispatch({
          type: REFRESH_USER_DATA,
          freshUserData: {
            ...response.data,
            role: ROLE.pharmacy
          }
        })
      })
      .catch((e) => {
        dispatch({
          type: REFRESH_DATA_FAILED
        })
      })
  }
}

export const verifyOtp = () => ({
  type: VERIFIED_OTP
})

export const logout = () => ({
  type: LOG_OUT
})

export const unAuthorizedAuth = () => ({
  type: UNAUTHORIZED_AUTH
})

export const resetError = () => ({
  type: RESET_ERROR_MSG
})

export const retrieveNcpdpData = (number, zipcode) => {
  return (dispatch) => {
    dispatch({
      type: RETRIEVING_NCPDP_DATA
    })

    return api
      .get(apiUrls.user.signupNcpdp, {
        params: { number, zipcode },
        headers: {
          Authorization: clientCredentials()
        }
      })
      .then((response) => {
        dispatch({
          type: RETRIEVED_NCPDP_DATA,
          payload: response.data
        })
      })
      .catch((e) => {
        dispatch({
          type: RETRIEVING_NCPDP_DATA_FAILURE
        })
        console.error(e)
      })
  }
}
