import {
  RETRIEVING_NCPDP_DATA,
  RETRIEVED_NCPDP_DATA,
  RETRIEVING_NCPDP_DATA_FAILURE,
  LOGGING_IN,
  LOGGED_IN,
  LOGGING_IN_FAILURE,
  REFRESH_USER_DATA,
  RESET_LOGIN,
  UNAUTHORIZED_AUTH,
  RESET_ERROR_MSG,
  LOG_OUT,
  VERIFIED_OTP
} from '../actions/user'
import {
  getPharmacyPermissions,
  getWholesalerPermissions,
  isWholesaler
} from '../helpers/userHelper'

const initialState = {
  loading: false,
  isLoggingIn: false,
  error: '',
  userData: {},
  ncpdpData: [],
  isAuthorized: false
}

const filterUserData = (userObj) => {
  const allowedUserData = [
    'id',
    'supplier_id',
    'contact_person',
    'email',
    'dea_number',
    'status',
    'avatar',
    'master_user_id',
    'points',
    'gln_number',
    'default_avatar_color',
    'display_name',
    'business_legal_name',
    'state_id',
    'user_onboarding',
    'p2p_supplier?',
    'p2p_selling_enabled?',
    'is_sub_user?',
    'has_sub_users?',
    'supplier',
    'role'
  ]
  const filteredData = Object.keys(userObj)
    .filter((key) => allowedUserData.includes(key))
    .reduce(
      (obj, key) => ({
        ...obj,
        [key]: userObj[key]
      }),
      {}
    )

  return {
    ...filteredData,
    permissions: isWholesaler(userObj)
      ? getWholesalerPermissions(userObj)
      : getPharmacyPermissions(userObj)
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RETRIEVING_NCPDP_DATA:
      return {
        ...state,
        loading: true
      }
    case RETRIEVED_NCPDP_DATA:
      return {
        ...state,
        loading: false,
        ncpdpData: action.payload
      }
    case RETRIEVING_NCPDP_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        ncpdpData: []
      }
    case LOGGING_IN:
      return {
        ...state,
        loading: true,
        isLoggingIn: true
      }
    case LOGGING_IN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case RESET_LOGIN:
      return {
        ...state,
        isLoggingIn: false,
        error: ''
      }
    case UNAUTHORIZED_AUTH:
      return { ...initialState, error: 'Your session has expired' }
    case RESET_ERROR_MSG:
      return {
        ...state,
        error: ''
      }
    case VERIFIED_OTP:
      return {
        ...state,
        isAuthorized: true,
        loading: false,
        error: ''
      }
    case LOGGED_IN:
      return {
        ...state,
        loading: false,
        userData: filterUserData(action.payload),
        error: '',
        isAuthorized: !action.otpEnabled
      }
    case REFRESH_USER_DATA:
      return {
        ...state,
        userData: filterUserData(action.freshUserData)
      }
    case LOG_OUT:
      return {
        ...initialState
      }

    default:
      return state
  }
}
