import dayjs from 'dayjs'
import { api } from '../axios'
import { apiUrls } from '../helpers/apiHelper'

export const FETCHING_PROMPTS = 'messages/FETCHING_PROMPTS'
export const FETCHING_PROMPTS_FAILURE = 'messages/FETCHING_PROMPTS_FAILURE'
export const RECEIVED_PROMPTS = 'messages/RECEIVED_PROMPTS'
export const CREATING_CONVERSATION = 'messages/CREATING_CONVERSATION'
export const CREATING_CONVERSATION_FAILURE =
  'messages/CREATING_CONVERSATION_FAILURE'
export const CREATED_CONVERSATION = 'messages/CREATED_CONVERSATION'

export const FETCHING_CONVERSATIONS = 'messages/FETCHING_CONVERSATIONS'
export const RESET_SELECTED_DATA = 'messages/RESET_SELECTED_DATA'
export const FETCHING_CONVERSATIONS_FAILURE =
  'messages/FETCHING_CONVERSATIONS_FAILURE'
export const RECEIVED_CONVERSATIONS = 'messages/RECEIVED_CONVERSATIONS'
export const FETCHING_CONVERSATION_CONTEXT =
  'messages/FETCHING_CONVERSATION_CONTEXT'
export const RECEIVED_CONVERSATION_CONTEXT =
  'messages/RECEIVED_CONVERSATION_CONTEXT'
export const FETCHING_CONVERSATION_CONTEXT_FAILURE =
  'messages/FETCHING_CONVERSATION_CONTEXT_FAILURE'
export const RELOADING_CONVERSATIONS = 'messages/RELOADING_CONVERSATIONS'
export const SELECTED_CONVERSATION = 'messages/SELECTED_CONVERSATION'
export const REFRESHING_CONVERSATION_MESSAGES =
  'messages/REFRESHING_CONVERSATION_MESSAGES'
export const UPDATING_CONVERSATION = 'messages/UPDATING_CONVERSATION'
export const CLOSED_CONVERSATION = 'messages/CLOSED_CONVERSATION'
export const HIDE_LOADING_CONVO = 'messages/HIDE_LOADING_CONVO'

export const SENDING_MESSAGE = 'messages/SENDING_MESSAGE'
export const SENDING_MESSAGE_FAILURE = 'messages/SENDING_MESSAGE_FAILURE'
export const UPDATING_MESSAGE = 'messages/UPDATING_MESSAGE'

// Viewing messages
export const loadPrompts = (context, parentPromptId = null) => {
  return (dispatch) => {
    dispatch({
      type: FETCHING_PROMPTS
    })

    return api
      .get(apiUrls.messages.getPrompts, {
        params: {
          parent_prompt_id: parentPromptId,
          context_type: context
        }
      })
      .then((response) => {
        dispatch({
          type: RECEIVED_PROMPTS,
          data: response.data
        })
      })
      .catch((e) => {
        dispatch({
          type: FETCHING_PROMPTS_FAILURE
        })
        console.error(e)
        alert(e.response.data.message)
      })
  }
}

export const createConversation = (payload, callback) => {
  return (dispatch) => {
    dispatch({
      type: CREATING_CONVERSATION
    })

    return api
      .post(apiUrls.messages.updateMessages, {
        recipient_user_id: payload.recipientUserId,
        prompt_id: payload.selectedPromptId,
        context_id: payload.contextId,
        context_type: payload.contextType,
        followup_message: payload.followupMessage
      })
      .then((response) => {
        dispatch({
          type: CREATED_CONVERSATION,
          payload: response.data
        })
      })
      .catch((e) => {
        dispatch({
          type: CREATING_CONVERSATION_FAILURE,
          message: e.response.data.message
        })
        console.error(e)
        alert(e.response.data.message)
      })
      .then(() => callback())
  }
}

export const loadConversations = (query = '', reloadListOnly = false) => {
  return (dispatch) => {
    dispatch({
      type: reloadListOnly ? RELOADING_CONVERSATIONS : FETCHING_CONVERSATIONS
    })

    return api
      .get(apiUrls.messages.getMessages, {
        params: {
          search: query
        }
      })
      .then((response) => {
        dispatch({
          type: RECEIVED_CONVERSATIONS,
          data: response.data.conversations
        })

        if (response.data.conversations.length) {
          // Also automatically select the first conversation in the list
          dispatch(selectConversation(response.data.conversations[0]))
        }
      })
      .catch((e) => {
        dispatch({
          type: FETCHING_CONVERSATIONS_FAILURE
        })

        console.error(e)
        {
          e !== 'undefined' && alert(e.response.data.message)
        }
      })
  }
}

export const resetSelectedConversationsData = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_SELECTED_DATA
    })
  }
}

export const selectConversation = (conversation) => {
  return (dispatch) => {
    dispatch({
      type: RESET_SELECTED_DATA
    })
    dispatch({
      type: SELECTED_CONVERSATION,
      selectedConversation: conversation,
      messages: conversation.messages
    })

    return api
      .get(apiUrls.messages.getConversation(conversation.id))
      .then((response) => {
        dispatch({
          type: REFRESHING_CONVERSATION_MESSAGES,
          data: response.data,
          convoId: conversation.id
        })
        dispatch(retrieveConversationContext(response.data))
      })
      .catch((e) => {
        console.log(e.response.data.message)
      })
  }
}

export const fetchCurrentConversation = (convoId) => {
  return (dispatch) => {
    dispatch({
      type: RESET_SELECTED_DATA
    })
    return api
      .get(apiUrls.messages.getConversation(convoId))
      .then((response) => {
        dispatch({
          type: SELECTED_CONVERSATION,
          data: response.data,
          selectedConversation: response.data,
          messages: response.data.messages
        })
        dispatch(retrieveConversationContext(response.data))
      })
      .then(() => {
        dispatch({
          type: HIDE_LOADING_CONVO
        })
      })
      .catch((e) => {
        console.log(e.response.data.message)
      })
  }
}

export const retrieveConversationContext = (ctx) => {
  return (dispatch) => {
    dispatch({
      type: FETCHING_CONVERSATION_CONTEXT
    })

    const ctxType = ctx.type || ctx.context_name
    let apiUrl = `/api/v1/${ctxType}/${ctx.context_id}`
    if (ctxType === 'products') {
      apiUrl = `${apiUrl}/parent`
    }
    return api
      .get(apiUrl)
      .then((response) => {
        dispatch({
          type: RECEIVED_CONVERSATION_CONTEXT,
          payload: response.data
        })
      })
      .catch((e) => {
        dispatch({
          type: FETCHING_CONVERSATION_CONTEXT_FAILURE
        })
        console.error(e)
      })
  }
}

export const createMessageInConversation = (convoId, user, messageBody) => {
  return (dispatch) => {
    const message = {
      body: messageBody,
      conversationId: convoId,
      senderUserId: user.id,
      unread: true,
      readAt: null,
      createdAt: dayjs().format()
    }
    dispatch({
      type: SENDING_MESSAGE,
      message
    })

    return api
      .post(apiUrls.messages.updateConversation(convoId), {
        body: messageBody
      })
      .then((_response) => {})
      .catch((e) => {
        dispatch({
          type: SENDING_MESSAGE_FAILURE,
          message: message
        })
        alert(e.response.data.message)
      })
  }
}

export const closeConversation = (convo) => {
  return (dispatch) => {
    return api
      .patch(apiUrls.messages.closeConversation(convo.id), {})
      .then((response) => {
        dispatch({
          type: CLOSED_CONVERSATION,
          selectedConversation: response.data
        })
      })
      .catch((e) => {
        alert(e.response.data.message)
      })
  }
}

export const receiveDataFromChannel = (data) => {
  return (dispatch) => {
    if (data.message) {
      // We check the unread status of the message we received because if the
      // message has unread = false, it means this is an unread status update to an
      // existing message in the conversation instead of a new message coming in
      dispatch({
        type: data.message.unread ? SENDING_MESSAGE : UPDATING_MESSAGE,
        message: data.message
      })
    } else if (data.conversation) {
      dispatch({
        type: UPDATING_CONVERSATION,
        conversation: data.conversation
      })
    }
  }
}
