import '@babel/polyfill'
import 'raf/polyfill'
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import React from 'react'
import { hydrate } from 'react-dom'
import { BrowserRouter, Route } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { AlertProvider } from '@ezrirx/sourdough'

import { createConsumer } from '@rails/actioncable'

import { reduxStore, reduxPersistor } from './store/store'
import './i18n'

import LoadingState from './components/Common/LoadingState'
import loadable, { loadableReady } from '@loadable/component'

import { initSentry } from './config/initSentry'
import {
  FbPixelTracker,
  GATracker,
  LinkedinTracker,
  withTrackers
} from './components/Trackers'

const App = loadable(() => import('./App'))

initSentry()

const animationClasses = {
  enter: 'animated',
  enterDone: 'animated bounceInDown',
  exit: 'animated',
  exitActive: 'animated bounceOutUp'
}

const consumer = createConsumer(process.env.RAZZLE_ACTIONCABLE_URL)
const AppWithTracking = (props) => {
  if (process.env.NODE_ENV === 'production') {
    const TrackedApp = withTrackers(App)([
      FbPixelTracker,
      GATracker,
      LinkedinTracker
    ])
    return <TrackedApp consumer={consumer} {...props} />
  }
  return <App consumer={consumer} {...props} />
}
loadableReady().then(() => {
  hydrate(
    <HelmetProvider>
      <Provider store={reduxStore}>
        <PersistGate
          loading={<LoadingState size={150} />}
          persistor={reduxPersistor}
        >
          <BrowserRouter>
            <AlertProvider animationClasses={animationClasses} timeout={3200}>
              <Route component={AppWithTracking} />
            </AlertProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </HelmetProvider>,
    document.getElementById('root')
  )
})

if (module.hot) {
  module.hot.accept()
}
