import {
  PERFORMING_PRODUCT_SEARCH,
  PERFORMED_PRODUCT_SEARCH,
  PERFORMING_PRODUCT_SEARCH_FAILURE
} from '../actions/search'

const initialState = {
  searchingProducts: false,
  productSearchResults: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case PERFORMING_PRODUCT_SEARCH:
      return {
        ...state,
        searchingProducts: true,
        productSearchResults: {}
      }
    case PERFORMED_PRODUCT_SEARCH:
      return {
        ...state,
        searchingProducts: false,
        productSearchResults: action.payload
      }
    case PERFORMING_PRODUCT_SEARCH_FAILURE:
      return {
        ...state,
        searchingProducts: false
      }
    default:
      return state
  }
}
